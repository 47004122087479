import Page from '#/components/shared/ui/Page';
import DigitalFootprintDashboard from '#/components/pages/DigitalFootprint';

const DigitalFootprint = () => (
  <Page title="Digitaler Nachlass">
    <DigitalFootprintDashboard />
  </Page>
);

export default DigitalFootprint;
