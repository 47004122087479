import { Box, Container } from '@mui/material';
import DashboardIntro from '#/components/pages/DigitalFootprint/dashboard-intro';
import Services from '#/components/pages/DigitalFootprint/df-services/services-container';

export default function DigitalFootprintDashboard() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        <DashboardIntro />
        <Services />
      </Box>
    </Container>
  );
}
