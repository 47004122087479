import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ServiceCard from '#/components/pages/DigitalFootprint/df-services/service-card';
import dfServices from '#/components/pages/DigitalFootprint/df-services/services';
import useLocales from '#/hooks/useLocales';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function ServicesContainer() {
  const { translate } = useLocales();

  const { state } = useLocation();

  const [ownerID, setValueInLocalStorage] = useLocalStorage(
    'ownerID',
    state?.ownerID
  );

  useEffect(() => {
    if (state?.ownerID) {
      setValueInLocalStorage(state?.ownerID);
    }
  }, [state?.ownerID]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      {dfServices.map((service, i) => (
        <ServiceCard
          key={i}
          icon={service.icon}
          url={service.url}
          statsKey={service.statsKey}
          ownerID={ownerID}
          title={String(
            translate(
              `digitalFootprint.dashboardServiceCards.${service.value}.title`
            )
          )}
          description={String(
            translate(
              `digitalFootprint.dashboardServiceCards.${service.value}.description`
            )
          )}
        />
      ))}
    </Box>
  );
}
