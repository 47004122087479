const dfServices = [
  {
    icon: '/assets/icons/ic_important_information.svg',
    value: 'importantInformation',
    statsKey: 'important_information',
    url: '/dashboard/digitaler-nachlass/wichtige-informationen',
  },
  {
    icon: '/assets/icons/ic_digital_assets.svg',
    value: 'digitalAssets',
    url: '/dashboard/digitaler-nachlass/digital-vermoegen',
    statsKey: 'digital-assets',
  },
  {
    icon: '/assets/icons/ic_photo_videos.svg',
    value: 'picturesAndVideos',
    url: '/dashboard/digitaler-nachlass/fotos-videos',
  },
];

export default dfServices;
