import { Button, Card, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import Image from '#/components/shared/ui/Image';
import useServiceStats from '#/hooks/useServiceStats';
import useLocales from '#/hooks/useLocales';
import { useGetDigitalAssets } from '#/api/digitalAssets';

type Props = {
  icon: string;
  title: string;
  description: string;
  url: string;
  statsKey?: string;
  buttonLabel?: string;
  ownerID?: string;
};

export default function ServiceCard({
  icon,
  title,
  description,
  url,
  statsKey,
  buttonLabel,
  ownerID,
}: Props) {
  const { translate } = useLocales();

  const getServiceInfo = useServiceStats();
  const serviceStarted =
    // @ts-ignore
    getServiceInfo[`${statsKey}`] !== null &&
    // @ts-ignore
    getServiceInfo[`${statsKey}`] !== undefined;

  const enableRequest = statsKey === 'digital-assets';

  const { data: digitalAssets } = useGetDigitalAssets(
    1,
    ownerID || undefined,
    undefined,
    undefined,
    undefined,
    enableRequest
  );

  return (
    <Card
      sx={{
        p: 2,
        flex: 1,
        width: {
          xs: '100%',
          lg: '30%',
        },
      }}
    >
      <Image
        src={icon}
        sx={{
          width: 60,
          height: 60,
          marginBottom: 2,
        }}
        alt={`${title} - ${title}`}
      />
      <Stack spacing={2}>
        <Typography variant="subtitle1" component="h6" sx={{ marginTop: 2 }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="normal"
          sx={{ height: '120px' }}
        >
          {description}
        </Typography>
        <Link to={url} component={RouterLink} sx={{ width: 'fit-content' }}>
          <Button variant="contained" color="primary">
            <Typography variant="body2">
              {/* @ts-ignore */}
              {serviceStarted || digitalAssets?.results?.length > 0
                ? String(translate('global.manage'))
                : buttonLabel || String(translate('global.getStarted'))}
            </Typography>
          </Button>
        </Link>
      </Stack>
    </Card>
  );
}
